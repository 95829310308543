@import url("https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@400..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

body {
  font-family: "Wix Madefor Display", sans-serif;
  background: #040507;
  .App {
    background: url("./assets/siteBg.png");
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    &.bg_remove {
      background: none !important;
    }
  }
  header {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(8.800000190734863px);
    .navBtns {
      gap: 23px;
      li {
        button {
          @media screen and (max-width: 767px) {
            width: 100%;
          }
        }
      }
    }
    .navbar-brand {
      svg {
        @media screen and (max-width: 767px) {
          max-width: 180px;
        }
      }
    }
  }
  .tokenContainer {
    min-height: calc(100vh - 116px);
    .tokenBox {
      border-radius: 31.793px;
      border: 0.757px solid rgba(255, 255, 255, 0.7);
      background: rgba(255, 255, 255, 0.08);
      backdrop-filter: blur(16.5020694732666px);

      padding: 30px 100px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      max-width: 800px;
      .tokenLogo {
        max-width: 166px;
        margin: auto;
      }
      @media screen and (max-width: 767px) {
        padding: 15px;
        border-radius: 32px;
        .tokenLogo {
          max-width: 121px;
          margin: auto;
          svg {
            width: 121px;
            height: 121px;
          }
        }
      }
      .tokenHeading {
        text-align: center;
        .tokenName {
          color: #ffffff;
          font-size: 24px;
          font-weight: 800;
          line-height: 35.28px;
        }
        .tokenMain {
          color: #fddc5c;
          font-size: 48px;
          font-weight: 700;
          line-height: 65.6px;
          @media screen and (max-width: 767px) {
            font-size: 37px;
            font-weight: 700;
            line-height: 52.6px;
          }
        }
        span {
          color: #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          opacity: 0.8;
        }
      }
      .tokenStage {
        text-align: center;
        .tokenStageName {
          color: #ffffff;
          font-size: 22px;
          font-weight: 700;
          line-height: 28.24px;
        }
        .tokenBoxBg {
          border: 1px solid #eccb663d;
          border-radius: 6px;
          background: linear-gradient(
            90deg,
            rgba(207, 145, 64, 0) 0%,
            rgba(236, 203, 102, 0.13) 51%,
            rgba(207, 145, 64, 0) 100%
          );
          &.tokenBgWidth {
            @media screen and (min-width: 767px) {
              max-width: calc(100% - 340px);
            }
          }
          .barBox {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 20px;
            padding: 21px;
            @media screen and (max-width: 767px) {
              gap: 10px;
              padding: 15px;
            }
            .heading {
              margin: 0;
              color: #ffffff;
              font-size: 14px;
              font-weight: 600;
              line-height: 20.16px;
            }

            .amount {
              color: #fddc5c;
              font-size: 24px;
              font-weight: 700;
              line-height: 35.28px;
              word-break: break-all;
              @media screen and (max-width: 767px) {
                font-size: 18px;
                font-weight: 700;
                line-height: 32.6px;
              }
            }
          }
          .progressBar {
            margin-top: 15px;
            background: #6a6c6d;
            height: 8px;
            border-radius: 8px;
            .progressAmount {
              background: #fddc5c;
              border-radius: 8px;
              position: relative;
              height: 100%;
              .percentage {
                position: absolute;
                left: 100%;
                color: #ffffff;
                bottom: calc(100% + 5px);
                font-size: 11.15px;
                font-weight: 600;
                line-height: 13.46px;
              }
            }
          }
          .countDownDiv {
            text-align: center;
            .heading {
              color: #ffffff;
              font-size: 18px;
              font-weight: 700;
              line-height: 25.2px;
            }
            .countdown {
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              align-items: center;
              gap: 5px;
              .timeStamp {
                color: #fddc5c;
                font-family: "Inter", sans-serif;
                font-size: 38px;
                font-weight: 600;
                line-height: 53.25px;
                display: grid;
                @media screen and (max-width: 767px) {
                  font-size: 34px;
                  line-height: 43.25px;
                }
                span {
                  color: #ffffff;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 20.16px;
                }
              }
            }
          }
          .tokenAmount {
            display: flex;
            justify-content: start;
            align-items: center;
            .amountInputDiv {
              display: flex;
              align-items: flex-start;
              flex-direction: column;
              gap: 7px;
              width: 100%;
              padding: 7px 16px;
              input {
                background: transparent;
                border: none;
                width: 100%;
                max-width: 100%;
                outline: none;
                color: #fff;
                font-size: 18px;
                font-weight: 700;
                line-height: 20.28px;
                @media screen and (max-width: 767px) {
                  font-size: 24px;
                }
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }
                -moz-appearance: textfield;
              }
              span {
                font-size: 14px;
                line-height: 100%;
                opacity: 0.5;
                color: #ffffff;

                @media screen and (max-width: 767px) {
                  display: none;
                }
              }
            }
            .amountInUsdt {
              color: #fddc5c;
              font-size: 24px;
              font-weight: 700;
              line-height: 35.28px;
              margin: 0;
              text-align: center;
              max-width: 100%;
              overflow: hidden;
            }
            .tokenChainName {
              // min-width: 150px;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 5px;
              height: 100%;
              padding: 21px 12px;
              border-radius: 2px;
              background: rgb(217, 217, 217, 0.1);
              svg {
                width: 25px;
                height: 25px;
              }
            }
          }
        }
      }
    }
    .mobileAmount {
      display: none;
      @media screen and (max-width: 767px) {
        display: inline;
        color: #fff;
        opacity: 0.65;
        font-size: 18px;
        font-weight: 700;
        line-height: 20.28px;
      }
    }
    .addCXC {
      margin-bottom: 0;
      color: #fddc5c;
      font-size: 16px;
      font-weight: 600;
      line-height: 24.15px;
      cursor: pointer;
      span {
        border-radius: 50%;
        background: #fddc5c;
        margin-right: 8px;
        color: #000;
        display: inline;
        justify-content: center;
        align-items: center;
        width: 15px;
        height: 15px;
        padding: 0px 4px;
      }
    }
  }
  .mobilrMetaMask {
    text-align: center;
    @media screen and (min-width: 768px) {
      display: none;
    }
  }
  .customContainer {
    max-width: calc(100% - 20px);
    margin: 0 auto;
    @media screen and (min-width: 1400px) {
      max-width: 1320px;
    }
    @media screen and (min-width: 1700px) {
      max-width: 1620px;
    }
    @media screen and (min-width: 1999px) {
      display: flex;
      justify-content: center;
      align-items: center;
      .tokenBox {
        transform: translateY(-58px);
      }
    }
  }
  .customOutlineBtn {
    font-size: 22px;
    font-weight: 500;
    line-height: 30.24px;
    padding: 15px 28px;
    border-radius: 47px;
    border: 1px solid #ffffff;
    outline: none;
    box-shadow: none;
    color: #ffffff;
    background: transparent;
    &.dropBtn {
      position: relative;
      padding-right: 45px;
      &::after {
        position: absolute;
        content: "";
        top: 0%;
        right: 20px;
        height: 100%;
        width: 15px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25px' height='25px' viewBox='0 0 24 24'%3E%3Cpath fill='%23ffffff' d='M11.18 15.83L6.543 9.203C5.892 8.275 6.556 7 7.689 7h8.622c1.133 0 1.797 1.275 1.147 2.203l-4.639 6.627a1 1 0 0 1-1.638 0'/%3E%3C/svg%3E");
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
  .colorBtn {
    background: #fddc5c;
    padding: 15px 28px;
    border-radius: 47px;
    font-size: 22px;
    font-weight: 600;
    line-height: 28.24px;
    color: #fff;
    border: none;
    max-width: 500px;
    &:disabled {
      background: #fddc5c75;
      padding: 15px 28px;
      border-radius: 47px;
      font-size: 22px;
      font-weight: 600;
      line-height: 28.24px;
      border: none;
      color: #ffffff8a;
      max-width: 500px;
      cursor: not-allowed;
    }
  }
  .linkClass {
    color: #fddc5c;
    text-decoration: none;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.15px;
    position: relative;
    &::after {
      position: absolute;
      content: "";
      top: 100%;
      left: 0;
      width: 100%;
      height: 1px;
      background: #fddc5c;
    }
  }
  .socialIcons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 48px;
    margin-top: 15px;
    @media screen and (max-width: 767px) {
      gap: 12px;
    }
  }
  .socialLinks {
    border-radius: 50%;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    &.whiteBg {
      background: #fff;
    }
  }
  .loaderDiv {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 9999999;
    background: #000000c7;
    p {
      margin-top: 15px;
      font-size: 22px;
      font-weight: 500;
      line-height: 30.24px;
      color: #ffffff;
    }
  }
  .customTooltip {
    position: relative;
    display: inline-block;
    cursor: auto;
    .tooltipText {
      visibility: hidden;
      width: max-content;
      @media screen and (max-width: 767px) {
        width: auto;
      }
      color: #fff;
      background: linear-gradient(
        90deg,
        #040507a1 0%,
        #040507bb 51%,
        #040507a1 100%
      );
      backdrop-filter: blur(21.7999992371px);
      padding: 15px;
      text-align: center;
      border-radius: 6px;
      position: absolute;
      z-index: 1;
      bottom: calc(100% + 10px);
      left: 50%;
      transform: translateX(-50%);
    }
    &:hover {
      .tooltipText {
        visibility: visible;
      }
      &::after {
        content: "";
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        width: 20px;
        height: 20px;
        background: linear-gradient(
          135deg,
          #00000000 0%,
          #040507bb 51%,
          #040507a1 100%
        );
        backdrop-filter: blur(21.7999992371px);
      }
    }
  }
}

.disclaimer {
  color: white;
  justify-content: center;
  display: flex;
  margin-top: 2vh;
  cursor: pointer;
}

.disclaimer-data {
  color: #fff;
  h1 {
    font-weight: 600;
    margin-bottom: 26px;
  }
  .topStrong {
    line-height: 100%;
    margin-bottom: 10px;
    opacity: 1;
    strong {
      font-size: 22px;
      font-weight: 700;
    }
  }
  p {
    font-size: 16px;
    font-weight: 400;
    opacity: 0.85;
    line-height: 150%;
  }
  ol {
    list-style: none;
    padding: 0;
    li {
      padding-left: 25px;
      position: relative;
      margin-bottom: 22px;
      &::before {
        position: absolute;
        content: "";
        height: 14px;
        width: 14px;
        right: 100%;
        top: 11.5px;
        transform: translateX(15px);
        background-image: url("./assets/listIcon.svg");
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
      }
      strong {
        font-size: 18px;
        font-weight: 800;
        line-height: calc(100% + 20px);
      }
    }
  }
}
